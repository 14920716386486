.post {
    margin: 20px;
}

.card {
    display: inline-block;
    box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);
    border-radius: 10px;
    transition: transform 0.4s ease;
}

.card:hover {
    transform: scale(1.1);
}

.card-buttons {
    display: flex;
    justify-content: space-evenly;
}

.card-button-vote {
    width: 1.5rem;
    cursor: pointer;
}

.card-button-vote span {
    pointer-events: none;
}

.thumbs path {
    fill: grey;
    pointer-events: none;
}

.hide {
    display: none;
}