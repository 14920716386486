.flex-posts {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feed {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.feed h4 {
    border: 1px solid black;
    background-color: rgb(32, 33, 43);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
}

.post-groups {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}



::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #201f2063;
}